<style>
@media screen {
  #print {
    display: none;
  }
}

@media print {
  .modal-lg {
    width: 100% !important;
    z-index: 3;
  }

  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }

  #print-order {
    padding: 50px 40px;
    position: absolute;
    top: 0;
  }

  .hidde {
    display: none;
  }
}
</style>

<template>


  <div
    id="right-modal-kit"
    class="modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-right">
      <div class="modal-content">
        <div class="modal-header border-0 hidde">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-hidden="true"
          >
            <i class="ti-close font-20"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-12">
            <div class="row justify-content-center" v-if="!readOnly">
              <button
                type="button"
                class="btn btn-info font-13 font-b-5 my-1 mr-2"
                style="background-color: var(--color)"
                data-bs-dismiss="modal"
              >
                CONTINUAR COMPRANDO
                <i class="icon-basket-loaded font-17 align-middle pl-1"></i>
              </button>
              <button
                type="submit"
                class="btn btn-info font-13 font-b-5 my-1"
                style="background-color: #222"
                @click="addOrder"
              >
                FINALIZAR PEDIDO
                <i class="icon-rocket font-17 align-middle pl-1"></i>
              </button>
            </div>
            <div class="row">
              <div class="col-12 p-0" id="print-order">
                <div class="col-12 pt-4">
                  <img
                    v-if="enterprise.image"
                    :src="enterprise.image"
                    class="img-fluid"
                    title="Logo"
                    style="max-height: 40px"
                  />
                </div>
                <div class="col-12 pt-4">
                  <p class="page-txt-view-chamado">PEDIDO</p>
                  <h3
                    class="page-title-view-chamado text-dark font-weight-medium mb-1"
                  >
                    {{ order.id ? order.id : "" }}
                  </h3>
                </div>
                <div class="col-12 col-md-4 col-lg-4 pt-4" v-if="isAdmin">
                  <p class="page-txt-view-chamado">NOME</p>
                  <h3
                    class="page-title-view-chamado text-dark font-weight-medium mb-1"
                  >
                    {{ order.user.first_name }} {{ order.user.last_name }}
                  </h3>
                </div>
                <div class="col-12 col-md-4 col-lg-4 pt-4" v-if="isAdmin">
                  <p class="page-txt-view-chamado">CPF/CNPJ</p>
                  <h3
                    class="page-title-view-chamado text-dark font-weight-medium mb-1"
                  >
                    {{
                      order.user.cpf
                        ? order.user.cpf.slice(0, 8)
                        : order.user.cnpj.slice(0, 8)
                    }}*
                  </h3>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 text-left pt-4">
                      <p class="page-txt-view-chamado">KIT PERSONALIZAÇÃO</p>
                      <h3
                        class="page-title-view-chamado-2 font-weight-medium mb-1"
                        style="color: var(--color)"
                      >
                        UNIDADE {{ unity.number }}
                      </h3>
                    </div>
                    <div class="col-6 text-right">
                      <p class="font-b-4 font-13">
                        TOTAL<br />
                        <span
                          class="font-30 font-b-5 mb-0 pt-2"
                          style="color: var(--color)"
                          >R$ {{ formatMoney(total) }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 p-0 align-self-center mt-4 px-3 py-3"
                  style="background-color: #f8f8f8; border-radius: 15px"
                  v-for="(item, index) in order.kits"
                  :key="item.id"
                >
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-7 col-md-6 col-lg-10 p-0 order-3 align-self-center"
                      >
                        <div class="col-12">
                          <div class="row">
                            <div
                              class="col-12 col-md-12 col-lg-8 align-self-center pad-content-list py-2"
                            >
                              <h6 class="card-body-list-title">
                                {{ item.name }}
                              </h6>
                              <div
                                v-for="(group, key) in item.products"
                                :key="key"
                                class="mt-2"
                              >
                                <h3
                                  class="font-b-1"
                                  style="color: var(--color)"
                                >
                                  |
                                  <span
                                    class="mb-0 font-b-3"
                                    style="color: #7e7e7e"
                                    >{{ key }}</span
                                  >
                                </h3>
                                <hr class="my-0" />
                                ({{
                                  group
                                    .map((elem) => {
                                      return (
                                        elem.product.quantity +
                                        " " +
                                        elem.product.name
                                      );
                                    })
                                    .join(" | ")
                                }})
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-auto ml-auto align-self-center text-muted pad-content-list"
                            >
                              <span
                                class="font-20 font-b-5 mb-0"
                                style="color: var(--color)"
                                >R$ {{ formatMoney(item.price) }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto order-4 pad-content-list-cta"
                        v-if="!readOnly"
                      >
                        <a
                          class="btn btn-dark rounded-circle btn-circle font-16 card-body-list-cta"
                          href="javascript:void(0);"
                          @click="removeKit(index)"
                          ><i class="far fa-trash-alt text-white"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  style="
                    height: 1px;
                    background-color: #ccc;
                    width: 100%;
                    border: 0;
                  "
                />
                <div class="col-12 p-0 align-self-center px-3 py-0">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list py-2"
                      >
                        <h6 class="card-body-list-title pb-2 pt-3">
                          Bancada cozinha
                        </h6>
                        <img
                          v-if="personalization_type"
                          :src="personalization_type.image"
                          class="img-fluid off aos-init"
                          data-aos="fade"
                          data-aos-delay="300"
                          alt="Qsuíte"
                          style="max-height: 180px; border-radius: 12px"
                        />
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list py-2"
                      >
                        <div class="form-group mb-0" v-if="!readOnly">
                          <label class="d-none">Alterar</label>
                          <b-form-select
                            v-model="personalization_type_id"
                            :options="personalization_types"
                          ></b-form-select>
                        </div>
                        <div v-else>
                          {{ personalization_type.name }}
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-auto ml-auto align-self-center text-muted pad-content-list"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="col-12 p-0 align-self-center px-3 py-0">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list py-2"
                      >
                        <h6 class="card-body-list-title pb-2 pt-3">
                          Modelo pedra cozinha
                        </h6>
                        <img
                          :src="stone.image"
                          class="img-fluid"
                          alt="Qsuíte"
                          style="max-height: 90px; border-radius: 12px"
                        />
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list py-2"
                      >
                        <div class="form-group mb-0" v-if="!readOnly">
                          <label class="d-none">Alterar </label>
                          <b-form-select
                            v-model="personalization_type_id"
                            :options="stones"
                          ></b-form-select>
                        </div>
                        <div v-else>
                          {{ stone.name }}
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-auto ml-auto align-self-center text-muted pad-content-list"
                      >
                        <span
                          class="font-20 font-b-5 mb-0"
                          style="color: var(--color)"
                          >+ R$ {{ formatMoney(stone.price) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  style="
                    height: 1px;
                    background-color: #ccc;
                    width: 100%;
                    border: 0;
                  "
                />
                <div class="col-12 p-0 align-self-center px-3 py-0">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list py-2"
                      >
                        <h6 class="card-body-list-title pb-2 pt-3">
                          Pedra Banhos
                        </h6>
                        <img
                          :src="personalization_type.image"
                          class="img-fluid off aos-init"
                          data-aos="fade"
                          data-aos-delay="300"
                          alt="Qsuíte"
                          style="max-height: 180px; border-radius: 12px"
                        />
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list py-2"
                      >
                        <div class="form-group mb-0" v-if="!readOnly">
                          <label class="d-none">Alterar</label>
                          <b-form-select
                            v-model="personalization_type_id"
                            :options="personalization_types"
                          ></b-form-select>
                        </div>
                        <div v-else>
                          {{ personalization_type.name }}
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-auto ml-auto align-self-center text-muted pad-content-list"
                      ></div>
                    </div>
                  </div>
                </div>
                <!-- STONE NICHE -->
                <div
                  class="col-12 p-0 align-self-center px-3 py-0"
                  v-if="personalization_type_item"
                >
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list py-2"
                      >
                        <h6 class="card-body-list-title pb-2 pt-3">
                          Tipo de pedra
                        </h6>
                        <img
                          :src="personalization_type_item.image"
                          class="img-fluid"
                          alt="Qsuíte"
                          style="max-height: 90px; border-radius: 12px"
                        />
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list py-2"
                      >
                        <div class="form-group mb-0" v-if="!readOnly">
                          <label class="d-none">Alterar </label>
                          <b-form-select
                            v-model="personalization_type_item_id"
                            :options="personalization_type_items"
                          ></b-form-select>
                        </div>
                        <div v-else>
                          {{ personalization_type_item.name }}
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-12 col-lg-auto ml-auto align-self-center text-muted pad-content-list"
                      >
                        <span
                          class="font-20 font-b-5 mb-0"
                          style="color: var(--color)"
                          >+ R$ {{ formatMoney(personalization_type_item.price) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 pt-4">
                  <div class="form-group mb-0">
                    <label>Obs.: </label>
                    <textarea
                      class="form-control"
                      rows="6"
                      placeholder="Deixe aqui sua observação"
                      v-model="order.observation"
                      :disabled="readOnly"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12 pt-4 d-flex justify-content-between">
                  <p class="font-b-4 font-13 mb-1">
                    TOTAL
                    <span
                      class="font-30 font-b-5 mb-0 pt-2"
                      style="color: var(--color)"
                      >R$ {{ formatMoney(total) }}</span
                    >
                  </p>
                </div>
                <div class="col-12 pt-4">
                  <button
                    type="submit"
                    class="font-14 font-b-5 hidde btn btn-info mr-2"
                    @click="print()"
                    v-if="isAdmin"
                  >
                    Imprimir pedido
                  </button>
                  <button
                    type="submit"
                    class="btn btn-dark font-14 font-b-5 hidde"
                    style="background-color: #000"
                    @click="cancelOrder"
                    v-if="isAdmin"
                  >
                    Cancelar pedido
                  </button>
                </div>

                <div class="col-12 pt-2" v-if="!readOnly">
                  <div class="d-auto mx-auto">
                    <button
                      type="submit"
                      class="btn btn-info font-14 font-b-5"
                      style="background-color: var(--color)"
                      @click="addOrder"
                    >
                      {{ order.id ? "SALVAR PEDIDO" : "ENVIAR PEDIDO" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <?php include "kit-condicoes-comerciais.php"; ?> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderService from "@/services/resources/OrderService";
import OrderServiceCancel from "@/services/resources/OrderServiceCancel";
import KitConfigService from "@/services/resources/KitConfigService";
import Money from "@/mixins/money";
import UserService from "@/services/resources/UserService";
import TriggerService from "@/services/resources/TriggerService";
const serviceUser = UserService.build();
const serviceKit = KitConfigService.build();
const service = OrderService.build();
const serviceTrigger = TriggerService.build();
const serviceCancel = OrderServiceCancel.build();

export default {
  mixins: [Money],
  props: {
    order: {
      type: Object,
      default: {
        itens: [],
        kits: [],
        price: 0,
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      personalization_types: [],
      personalization_type_items_list: [],
      personalization_type_items: [],
      personalization_type_id: null,
      personalization_type_id: null,
      personalization_type_item_id: null,
      personalization_type_item: null,
      triggers: [],
      personalization_type: {
        name: null,
      },
      personalization_type_item: {
        image: null,
        price: 0,
      },
      enterprise: {
        image: null,
      },
      unity: {
        numberz: null,
      },
    };
  },
  computed: {
    total() {
      let sum = 0;

      if (!this.order.kits) {
        return 0;
      }

      for (let i = 0; i < this.order.kits.length; i++) {
        sum += this.order.kits[i].price ? this.order.kits[i].price : 0;
      }

      sum += this.personalization_type.price ? this.personalization_type.price : 0;
      sum += this.personalization_type_item.price ? this.personalization_type_item.price : 0;
      sum += this.personalization_type.price ? this.personalization_type.price : 0;
      if (this.personalization_type_item) sum += this.personalization_type_item.price;

      this.$emit("updateTotal", sum);

      return sum;
    },
    user() {
      return this.$store.getters.getProfile;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    // stones(){
    //     if(!this.stand){
    //         return [];
    //     }
    //     return this.personalization_type.stones.map(elem => {
    //         return{
    //             value: elem.stone.id,
    //             text: elem.stone.name
    //         }
    //     });
    // }
  },
  watch: {
    personalization_type_id(newValue) {
      this.selectKit(newValue);
    },
    personalization_type_item_id(newValue) {
      this.selectKit(newValue);
    },
    order() {
      this.fetchTrigger();
      this.selectUser();
    },
  },
  filters: {
    value(value) {
      return value.toFixed(2).replace(".", ",");
    },
  },
  methods: {
    getpersonalization_types(personalization_type_id) {
      if (this.triggers.length == 0) {
        return [];
      }

      for (let i = 0; i < this.triggers.length; i++) {
        for (let j = 0; j < this.triggers[i].itens.length; j++) {
          if (this.triggers[i].itens[j].item.id == personalization_type_id) {
            this.personalization_type = this.triggers[i].itens[j];
            break;
          }
        }
      }
    },
    cancelOrder() {
      let data = {
        id: this.order.id,
      };

      serviceCancel.destroy(data).then((resp) => {
        this.$bvToast.toast("Seu pedido foi cancelado com sucesso!", {
          title: "Pedido cancelado",
          autoHideDelay: 5000,
          type: "success",
        });
        window.location.reload();
      });
    },
    selectUser() {
      let data = {
        id: this.order.user_id,
      };

      serviceUser.read(data).then((resp) => {
        resp.enterprises.forEach((element) => {
          if (element.enterprise_id == this.order.enterprise_id) {
            this.enterprise = element.enterprise;
          }
        });
        resp.unities.forEach((element) => {
          if (element.unity_id == this.order.unity_id) {
            this.unity = element.unity;
          }
        });
      });
    },
    selectKit(id) {
      if (!id) {
        return;
      }

      let data = {
        id: id,
      };

      serviceKit
        .read(data)
        .then((resp) => {
          if (resp.type == "PERSONALIZATION_TYPE") {
            this.personalization_type = resp;
          } else if (resp.type == "PERSONALIZATION_TYPE_ITEM") {
            if (resp.id == this.personalization_type_item_id) {
              this.personalization_type_item = resp;
            } else {
              this.personalization_type_item = resp;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addOrder() {
      // if(this.order.kits.length == 0){
      //     this.$bvToast.toast('Você deve ser selecionar pelo menos um kit!', {
      //         title: 'Criar pedido',
      //         autoHideDelay: 5000,
      //         type: 'danger'
      //     })
      //     return;
      // }

      let order = Object.assign({}, this.order);
      order.price = this.total;
      order.itens = [this.personalization_type_id, this.personalization_type_id];
      if (this.personalization_type_item_id) {
        order.itens.push(this.personalization_type_item_id);
      }

      if (order.id) {
        service
          .update(order)
          .then((resp) => {
            this.$bvToast.toast("Seu pedido foi atualizado com sucesso!", {
              title: "Pedido atualizado",
              autoHideDelay: 5000,
              type: "success",
            });
          })
          .catch((err) => {
            this.$bvToast.toast("Ocorreu um erro ao atualizar seu pedido,", {
              title: "erro inesperado",
              autoHideDelay: 5000,
              type: "danger",
            });
          });
      } else {
        service
          .create(order)
          .then((resp) => {
            let modal = bootstrap.Modal.getInstance(
              document.getElementById("right-modal-kit")
            );
            modal.hide();
            if (window.sessionStorage.KitTema == "tema2") {
              this.$router.push("/kit/obrigado/v2");
            } else {
              this.$router.push("/kit/obrigado");
            }
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    removeKit(index) {
      let kit = this.order.kits[index];
      this.$delete(this.order.kits, index);
      if (kit.type == "MAIN") {
        for (let i = 0; i < this.order.kits.length; i++) {
          if (this.order.kits[i].type == "SECONDARY") {
            this.$delete(this.order.kits, i);
          }
        }
      }
    },
    fetchKitConfig() {
      this.personalization_types = [];
      this.personalization_type_items = [];

      if (!this.order.itens) {
        return;
      }

      this.order.itens.forEach((element, index) => {
        if (element.item.type == "PERSONALIZATION_TYPE") {
          this.personalization_type_id = element.item.id;
          this.personalization_type= element.item;
          this.personalization_types = [{ value: this.personalization_type_id, text: this.personalization_type.name }];

          for (let i = 0; i < this.triggers.length; i++) {
            for (let j = 0; j < this.triggers[i].itens.length; j++) {
              if (this.triggers[i].itens[j].item.id == this.personalization_type_id) {
                this.personalization_type_items = this.triggers[i].itens[j].personalization_type_items.map((elem) => {
                  return {
                    value: elem.personalization_type_item.id,
                    text: elem.personalization_type_item.name,
                  };
                });
              }
            }
          }

          if (element.personalization_type_item) {
            this.personalization_type_item = element.stone;
            this.personalization_type_id = element.personalization_type_item.id;
          }
        }  else if (element.item.type == "PERSONALIZATION_TYPE_ITEM") {
          if (this.order.itens[index - 1].item.type === "PERSONALIZATION_TYPE") {
            this.personalization_type_item = element.item;
            this.personalization_type_id = element.item.id;
          } else {
            this.personalization_type_item = element.item;
            this.personalization_type_item_id = element.item.id;
          }
        }
      });
    },
    fetchTrigger() {
      let data = {
        tipology_id: this.order.tipology_id,
      };
      serviceTrigger
        .search(data)
        .then((resp) => {

          this.triggers = resp;
          this.fetchKitConfig();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    print() {
      const modal = document.getElementById("print-order");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
    },
  },
};
</script>